.billingPage {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;

    .title {
        margin: 20px 0 20px 0;
        font-size: 30px;

        font-weight: bold;
        color: white;
    }

    .storageProgres {
        position: relative;
        width: 100%;

        .storageTxt {
            display: flex;
            justify-content: space-between;
            padding: 10px 3px;
            // font-weight: bold;
        }

        .sProgressBar {
            position: relative;
            width: 100%;
            height: 40px;
            background-color: rgba(255, 255, 255, .2);
            border-radius: 6px;
            overflow: hidden;

            .yeet {
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                height: 100%;
                background-color: #888888;

                &.warning {
                    background-color: #FF9C06;
                }

                &.danger {
                    background-color: #b32f2f;
                }
            }
        }
    }

    .planList {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;

        .planItem {
            position: relative;
            display: inline-block;
            width: 100%;
            min-height: 300px;
            background-color: #2F2F2F;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
            border: 2px solid transparent;

            &:hover {
                border: 2px solid #5f5f5f;
            }

            &.selected {
                border: 3px solid #8b8b8b;
            }

            .renewTxt {
                text-align: center;
                font-size: 15px;
            }

            .priceBanner {
                position: relative;
                width: 100%;
                height: 120px;
                background-color: black;

                .displayPrice {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 40px;
                    font-weight: bold;
                    color: white;
                }
            }

            .item-detail {
                position: relative;
                padding: 20px;

                .line {
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: space-between;
                }
            }

            .bottom-range {
                position: absolute;
                bottom: 0;
                padding: 7px 0 10px;
                width: 100%;
                // height: 50px;
                font-weight: bold;
                text-align: center;
                border-top: 1px solid rgba(255, 255, 255, .1);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .billingPage {
        padding: 0 20px 0 20px;
    }

    .planList {
        display: block !important;

        .planItem {
            height: 300px;
            margin-bottom: 20px;

            .bottom-range {
                position: absolute;
                bottom: 0;

            }
        }
    }
}