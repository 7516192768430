/* Definition font family */

@font-face {
    font-family: 'Anuphan';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local('Anuphan Thin'), local('Anuphan-Thin'), url(../public/font/Anuphan-Thin.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Anuphan';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Anuphan ExtraLight'), local('Anuphan-ExtraLight'), url(../public/font/Anuphan-ExtraLight.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Anuphan';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Anuphan Light'), local('Anuphan-Light'), url(../public/font/Anuphan-Light.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Anuphan';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Anuphan Regular'), local('Anuphan-Regular'), url(../public/font/Anuphan-Regular.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Anuphan';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Anuphan Medium'), local('Anuphan-Medium'), url(../public/font/Anuphan-Medium.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Anuphan';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Anuphan SemiBold'), local('Anuphan-SemiBold'), url(../public/font/Anuphan-SemiBold.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Anuphan';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Anuphan Bold'), local('Anuphan-Bold'), url(../public/font/Anuphan-Bold.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

:root {
    --primary-color: #1ba3e4;
    --bg-main: #1B1B1C;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    margin: 0;
    font-family: 'Anuphan', sans-serif;
    background-color: #1B1B1C;
    scroll-behavior: smooth;
    color: white;
    height: 100%;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0
}

a{
    color: white;
    text-decoration: none;
    outline: 0;
}

img{
    pointer-events: none;
}

.loadingCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centerContain {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.bg-dim-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(27, 27, 28, .5);
}

.btn-layout {
    padding: 5px 20px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .15);
    color: white;
    font-family: inherit;
    border: none;
    cursor: pointer;
    transition-duration: .3s;

    &:hover {
        background-color: rgba(255, 255, 255, .25);
    }

    &:focus {
        outline: 0;
    }
    .h1 {
        margin-bottom: 550px; 
    }
    
}
.planText {
    position: relative;
    display: inline;
    top: 350px;
    font-size: 35px;
    left: 2%;
    transform: translateX(-100%);
    text-align: center;
    font-weight: bold;
}

.plan-label-6month {
    position: relative;
    color: white;
    font-size: 20px;
    font-weight: bold;
    transform: translate(-290%, -30%);
}
.plan-label-9month {
    position: relative;
    color: white;
    font-size: 20px;
    font-weight: bold;
    transform: translate(-290%, -30%);
}
.plan-label-12month{
    position: relative;
    color: white;
    font-size: 20px;
    font-weight: bold;
}
.plan-label-24month{
    position: relative;
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.plan-container {
    border: 3px solid white;
    background-color: rgb(0, 0, 0);
    border-radius: 25px;
    padding: 20px;
    margin: 10px;
    transform: translate(-210%, 140%);
}

.plan-container-nine {
    border: 3px solid rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    border-radius: 25px;
    padding: 20px;
    margin: 10px;
    transform: translate(-60%, 35%);
}

.plan-container-twelve {
    border: 3px solid white;
    background-color: rgb(0, 0, 0);
    border-radius: 25px;
    padding: 20px;
    margin: 10px;
    transform: translate(75%, -70%);
}

.plan-container-twenty-four {
    border: 3px solid white;
    background-color: rgb(0, 0, 0);
    border-radius: 25px;
    padding: 20px;
    margin: 10px;
    transform: translate(210%, -175%);
}

.plan-container.highlighted {
    background-color: #6a6a6a; /* สีพื้นหลังเมื่อเน้น */
    color: rgb(255, 255, 255); /* สีข้อความเมื่อเน้น */
    border: 3px solid rgb(0, 0, 0);
}

.plan-container-nine.highlighted {
    background-color: #6a6a6a; /* สีพื้นหลังเมื่อเน้น */
    color: rgb(255, 255, 255); /* สีข้อความเมื่อเน้น */
    border: 3px solid rgb(0, 0, 0);
}

.plan-container-twelve.highlighted {
    background-color: #6a6a6a; /* สีพื้นหลังเมื่อเน้น */
    color: rgb(255, 255, 255); /* สีข้อความเมื่อเน้น */
    border: 3px solid rgb(0, 0, 0);
}

.plan-container-twenty-four.highlighted {
    background-color: #6a6a6a; /* สีพื้นหลังเมื่อเน้น */
    color: rgb(255, 255, 255); /* สีข้อความเมื่อเน้น */
    border: 3px solid rgb(0, 0, 0);
}

.space-label {
    position: absolute;
    color: white;
    font-size: 35px;
    font-weight: bold;
    //transform: translate(-90%, -320%);
}


.memory-label {
    font-size: 16px;
    margin-bottom: 5px;

}

.memory-bar {
    width: 300%;
    height: 35px;
    background-color: #1ba3e4; 
    border-radius: 25px;
    transform: translate(-64%, -1140%);
}

.cardNumber {
    //position: absolute;
    top: 550px;
    left: 850px;
    font-size: 20px;
    width: 300px; /* ปรับขนาดตามที่ต้องการ */
    height: 70px; /* ปรับขนาดตามที่ต้องการ */
    border-radius: 5px; /* ปรับขนาดตามที่ต้องการ */
}

form {
    //position: absolute;
    top: 550px;
    left: 850px;
    font-size: 60px;
    width: 620px; /* ปรับขนาดตามที่ต้องการ */
    height: 90px; /* ปรับขนาดตามที่ต้องการ */
}


.video-js {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;

    .vjs-resolution-button-label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 15px;
    }

    .vjs-current-time, .vjs-time-control, .vjs-duration {
        display: block !important;
    }
}

.modalContent {
    .title {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1.5px solid rgb(72 72 72);
    }
}