.join-team-page {
    position: relative;
    width: 100vw;
    height: 100vh;
    color: white;

    .closeBtn {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        transition-duration: .3s;

        background-color: transparent;
        border: none;
        color: white;
        font-size: 30px;
        z-index: 1000;

        &:hover {
            background-color: rgba(255, 255, 255, .15);
        }
    }

    button {
        background: rgba(255, 255, 255, .15);
        color: white;
        border: none;
        transition-duration: .3s;

        &:hover {
            background: rgba(255, 255, 255, .25);
        }

        &:active {
            background: rgba(255, 255, 255, .35);
        }
    }

    .sub {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .bgOver {
        position: absolute;
        top: 0;
        left: 0;
        width: 65vw;
        height: 100vh;
        opacity: 0.5;
        background-size: cover;
        z-index: 1;
    }
    
    .loginSide {
        position: absolute;
        top: 0;
        right: 0;
        width: 35vw;
        height: 100vh;
        background-color: var(--bg-main);
    }
    
    .centerContain {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40%;
        background-color: var(--bg-main);
        text-align: left;
    }
}

.teamList {
    .teamItem {
        margin-bottom: 30px;
        display: flex;
        width: 100%;
        height: 50px;

        .img {
            width: 50px;
            height: 50px;
            background-size: cover;
            background-position: center;
            border-radius: 50%;
            margin-right: 10px;
            border-radius: 50%;
            background-color: #ccc;
        }

        .detail {
            margin-left: 20px;
            text-align: left;

            .name {
                font-size: 1.2rem;
                font-weight: 600;
            }

            .peopleCount {
                font-size: 1rem;
                font-weight: 400;
            }
        }

        .rightPanel {
            margin-left: auto;
            display: flex;
            align-items: center;

            .openBtn {
                background: rgba(255, 255, 255, .15);
                color: white;
                border: none;
                transition-duration: .3s;
                padding: 10px 20px;
                border-radius: 5px;

                &:hover {
                    background: rgba(255, 255, 255, .25);
                }

                &:active {
                    background: rgba(255, 255, 255, .35);
                }
            }
        }
    }
}

.team-page-header {
    position: fixed;
    top: 0;
    right: 0;
    padding: 10px 20px;
    z-index: 1000;

    p {
        margin: 0;
        font-weight: 600;
        color: rgba(255, 255, 255, .75);
        cursor: pointer;

        &:hover {
            color: rgba(255, 255, 255, 1);
        }

    }
}

@media screen and (max-width: 768px) {
    .join-team-page {
        .centerContain {
            
            width: 100%;
            // margin-top: 50px;
            padding: 0 20px;
            background-color: var(--bg-main);
            text-align: left;

            
        }

        .btnBack {
            padding-left: 0;
        }

        .btnJoin {
            width: 100% !important;
        }

        button {
            white-space: nowrap;
        }

        .teamList {
            .teamItem {
                .img {
                    width: 50px;
                    height: 50px;
                    min-width: 50px;
                    min-height: 50px;
                }

                .detail {
                    margin-left: 5px;
                    width: 40%;
                    .name {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }

                .rightPanel {
                    .openBtn {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}