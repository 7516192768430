.timeline {
    margin: 0 auto;
    width: 90%;

    .title {
        margin: 20px 0 20px 0;
        font-size: 25px;
        color: white;
    }

    .actor-list {
        display: grid;
        // grid-template-columns: repeat(7, 1fr);
        grid-template-columns: repeat( auto-fit, minmax(200px, 0.25fr) );
        grid-gap: 10px;

    }

    .actor-item {
        display: inline-block;
        margin: 10px 10px 10px 10px;
        width: 200px;
        cursor: pointer;

        .circle-image {
            width: 100%;
            height: 200px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, .15);
            background-size: cover;
            background-position: center;

            &:hover {
                background-color: rgba(255, 255, 255, .25);
            }
        }

        .info {
            text-align: center;
            .actor-name {
                margin-top: 10px;
                font-size: 20px;
                font-weight: 600;
                color: white;
            }
    
            .detail {
                margin-top: 10px;
                font-size: 15px;
                color: white;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}


@media screen and (max-width: 768px) {
    .timeline {
        .actor-list {
            display: block;

            .actor-item {
                display: block;
                margin: 20px auto 40px;
                width: 200px;
            }
        }
    }
}