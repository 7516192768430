.activities {
    margin: 0 auto;
    width: 90%;
    max-width: 800px;

    .logTitle {
        position: relative;
        width: 100%;
        height: 100%;
        color: white;
        font-size: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }
    .logList {
        margin: 25px auto 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        border: 2px rgba(255, 255, 255, 0.12) solid;

        .logItem + .logItem {
            border-top: 1px solid rgba(255, 255, 255, .15);
        }

        .logItem {
            /* ... (เราไม่จำเป็นต้องเปลี่ยนอะไรเพิ่มเติมในส่วนนี้) */
            width: 100%;
            height: 100%;
            padding: 12px 15px 15px;
            // border-bottom: 1px solid rgba(255, 255, 255, .15);
            // background-color: rgba(255, 255, 255, .08);
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .leftSide {
                display: flex;
            }

            .logIcon {
                position: relative;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, .15);
                background-size: cover;
                overflow: hidden;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }
            .logContent {
                position: relative;
                margin-left: 10px;
                width: calc(100% - 50px);
                height: 100%;
                overflow: hidden;

                .logTitle {
                    display: flex;
                    margin-bottom: 5px;
                    // flex-direction: column; /* แสดงในแนวแกนตั้ง */
                    /* ... (เพิ่มความกว้างหรือขนาดตัวอักษรตามที่คุณต้องการ) */
                    span {
                        margin-right: 5px;
                    }
                }
                .logDesc {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    color: white;
                    font-size: 15px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .logTime {
                position: relative;
                width: 50px;
                height: 100%;
                color: white;
                font-size: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                right: 80%;
                display: none;
            }
        }
    }
    .date-info {
        display: flex;
        margin-bottom: 10px;
        align-items: flex-end; /* จัดให้วันที่และวันอยู่ด้านล่าง */
        
    
        .dayOfWeek, .day, .month, .year {
            font-size: 30px;
            font-weight: bold;
            color: rgb(255, 255, 255);
            margin-right: 10px; /* เพิ่มระยะห่างของข้อมูลวันที่ */
            //position: relative;
        }
    }
}

@media screen and (max-width: 768px) {
    .activities {

        .logDesc {
            white-space: pre-wrap !important;
        }
    }
}