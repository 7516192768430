
.previewModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition-duration: .3s;
    z-index: 2000;

    .modalBg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        transition-duration: .3s;
    }

    .modalContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 70vw;
        width: 1400px;
        height: 600px;
        border-radius: 10px;
        transition-duration: .3s;

        .bg-panel {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--bg-main);
        }

        .sub {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            overflow: hidden;
            border-radius: 10px;
        }

        .closeBtn {
            // position: absolute;
            // top: 10px;
            // right: 10px;
            width: 30px;
            height: 30px;
            cursor: pointer;
            transition-duration: .3s;

            background-color: transparent;
            border: none;
            color: white;
            font-size: 25px;
            z-index: 1000;

            &:hover {
                background-color: rgba(255, 255, 255, .15);
            }
        }

        .editBtn {
            // position: absolute;
            // top: 10px;
            // right: 50px;
            width: 30px;
            height: 30px;
            cursor: pointer;
            transition-duration: .3s;

            background-color: transparent;
            border: none;
            color: white;
            font-size: 15px;
            z-index: 1000;

            &:hover {
                background-color: rgba(255, 255, 255, .15);
            }
        }

        .videoPlayer {
            position: relative;
            width: 75%;
            height: 100%;
            background-color: black;
        }

        .videoInfo {
            position: relative;
            min-width: 250px;
            width: 30%;
            height: 100%;
            padding: 10px;
            transition-duration: .3s;

            .title {
                display: flex;
                justify-content: space-between;
                font-size: 20px;
                color: white;
                transition-duration: .3s;
            }

            .description {
                font-size: 15px;
                color: white;
                transition-duration: .3s;
                white-space: pre-wrap;
                height: 70%;
                overflow: auto;
            }

            .videoTags {
                margin-top: 10px;
                display: flex;
                flex-wrap: wrap;
                transition-duration: .3s;

                .tag {
                    margin-right: 10px;
                    margin-bottom: 10px;
                    padding: 5px 10px;
                    border-radius: 5px;
                    background-color: rgba(255, 255, 255, .15);
                    color: white;
                    font-size: 15px;
                    transition-duration: .3s;

                    &:hover {
                        background-color: rgba(255, 255, 255, .25);
                    }
                }
            }

            .bottomPanel {
                position: absolute;
                display: flex;
                bottom: 0;
                padding: 20px 10px;
                width: 100%;
                background-color: var(--bg-main);
            }
        }
    }
}


@media screen and (max-width: 1000px) {
    .previewModal {
        .modalContent {
            max-width: 100vw;
            width: 100%;
            height: 100%;
            .sub {
                display: block;

                .videoPlayer {
                    width: 100%;
                    height: 300px;
                }

                .videoInfo {
                    width: 100%;
                    height: 100%;
                    padding: 20px 10px 80px 10px;
                    overflow: auto;

                    .previewBtnPanel {
                        position: fixed;
                        top: 0;
                        right: 0;
                        background-color: rgba(0, 0, 0, .5);
                    }

                    .bottomPanel {
                        position: fixed;
                        bottom: 0;
                        padding: 20px 10px;
                        width: 100%;
                        background-color: var(--bg-main);
                    }
                }
            }
        }
    }
    
}