.HeaderUploader {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    color: white;
    background-color: #161616;
    z-index: 1000;

    .HeaderUploader-context {
        display: flex;
        align-items: center;
        background-color: transparent !important;
    }

   .burger {
        position: relative;
        margin: 0 10px;
        width: 20px;
        height: 15px;
        cursor: pointer;
        z-index: 10;

        .line {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 2px;
            background-color: white;
            transition-duration: .3s;
            transition-delay: .35s;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2) {
                top: 50%;
            }

            &:nth-child(3) {
                top: 100%;
            }
        }
    }

    .siteName {
       
        font-size: 1.5rem;
        font-weight: 700;
        color: var(--text-main);
        transition-duration: .3s;
        transition-delay: .35s;
    }

    .searchBox {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 25%;
        transition-duration: .3s;

        .icon {
            width: 30px;
            height: 30px;
            margin-left: 20px;
            cursor: pointer;
            transition-duration: .3s;
            transition-delay: .35s;

            &:hover {
                filter: invert(1);
                transition-delay: 0s;
            }
        }

        input {
            width: 100%;
            height: 30px;
            padding: 0 10px;
            border: none;
            border-radius: 7px;
            background-color: rgba(255, 255, 255, .15);
            color: white;
            transition-duration: .3s;

            &::placeholder {
                color: white;
                opacity: .5;
                transition-duration: .3s;
            }

            &:hover {
                background-color: rgba(255, 255, 255, .25);
                transition-delay: 0s;
            }

            &:focus {
                background-color: white;
                color: black;
                outline: 0;

                &::placeholder {
                    color: black;
                    opacity: .5;
                }
            }
        }
    }

    .userPanel {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        
        display: flex;
        align-items: center;
        transition-duration: .3s;

        .btnUpload {
            padding: 5px 20px;
            height: 30px;
            margin-right: 50px;
            background-color: rgba(255, 255, 255, .15);
            border: none;
            border-radius: 5px;
            color: white;
            font-family: inherit;
            cursor: pointer;
            transition-duration: .3s;

            &:hover {
                background-color: rgba(255, 255, 255, .25);
            }

            &:focus {
                outline: 0;
            }

            &:active {
                transform: scale(.95);
            }
        }

        .userContainer {
            min-width: 150px;
            display: flex;
            align-items: center;

            .userImg {
                width: 30px;
                height: 30px;
                background-size: cover;
                background-position: center;
                border-radius: 50%;
                margin-right: 10px;
                border-radius: 50%;
                background-color: #ccc;
                transition-duration: .3s;
                transition-delay: .35s;
            }
    
            .userName {
                transition-duration: .3s;
                transition-delay: .35s;
    
                .name {
                    font-size: 1rem;
                    font-weight: 600;
                    transition-duration: .3s;
                    transition-delay: .35s;
                }
    
                .role {
                    font-size: .8rem;
                    font-weight: 400;
                    opacity: .75;
                    transition-duration: .3s;
                    transition-delay: .35s;
                }
            }
        }
    }
}


@media screen and (max-width: 768px) {
    .small-nav {
        .siteName {
            display: none;
        }

        .pageName {
            left: 20px;
            right: unset;
        }
    }
}