.video-list {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(300px, 0.2fr) );
    grid-gap: 15px;
    width: 100%;
}

.video-list .base-v-item {
    position: relative;
    height: 250px;
}

.video-list .v-item {
    position: relative;
    display: block;
    padding: 7px;
    padding-bottom: 10px;
    /* grid-template-columns: 200px auto; */
    width: 100%;
    /* height: 165px; */
    max-height: 250px;
    background-color: #0F0F0F;
    border-radius: 20px;
    transition-duration: .25s;
    box-shadow: 0 0 5px 1px #222;
    overflow: hidden;
}

.video-list .v-item:hover {
    padding: 0;
    background-color: #0F0F0F;
    box-shadow: 0 0 10px 2px #222;
    max-height: 400px;
    border-radius: 10px;
    z-index: 200;
}

.video-list .v-item .thumbnail {
    position: relative;
    height: 180px;
    background-color: #404040;
    border-radius: 20px;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
}

.video-list .v-item:hover .thumbnail {
    border-radius: 0;
}

.video-list .v-item .thumbnail img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition-duration: 1s;
}

.video-list .v-item .thumbnail .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: rgba(255, 255, 255, .5);
    transition-duration: .25s;
}

.video-list .v-item .thumbnail:hover .play-icon {
    color: rgba(255, 255, 255, .75);
}

.video-list .v-item .thumbnail .duration {
    position: absolute;
    right: 3px;
    bottom: 3px;
    padding: 0 5px;
    background-color: rgba(83, 83, 83, 0.5);
    border-radius: 2px;
}

.video-list .v-item .content {
    display: block;
    grid-template-rows: auto auto auto;
    padding: 5px 10px;
    width: 100%;
    /* height: calc(50% - 40px); */
}

.video-list .v-item .content .title {
    position: relative;
    margin-bottom: 2px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 50px;
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.video-list .v-item .content .title:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 100%;
    content: '';
    background: linear-gradient(to right, rgba(15, 15, 15, 0), rgba(15, 15, 15, 1));
}

.video-list .v-item .content .subline {
    display: block;
    margin-bottom: 2px;
    font-size: 14px;
    color: rgba(255, 255, 255, .5);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.video-list .v-item .content .subline .linkClick {
    color: rgba(255, 255, 255, .5);
    cursor: pointer;
    transition-duration: .25s;
}

.video-list .v-item .content .subline .linkClick:hover {
    color: rgba(255, 255, 255, .75);
}

.video-list .v-item .content .subline .linkClick:active {
    color: rgba(255, 255, 255, .5);
    transition-duration: .1s;
}

.video-list .v-item .content .desc {
    height: 100%;
    font-size: 15px;
    /* color: white; */
    overflow: auto;
    opacity: 0;
    transition-duration: .25s;
    height: fit-content;
    white-space: pre-wrap;
}

.video-list .v-item:hover .content .desc {
    opacity: 1;
}

.video-list .v-item .content .tags {
    display: block;
    padding: 5px 0;
    width: 100%;
    height: 40px;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
}

.video-list .v-item .content .tags p {
    padding: 0 7px;
    margin: 3px 0;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    color: white;
    background-color: rgb(102, 102, 102);
    border-radius: 3px;
    cursor: pointer;
    transition-duration: .2s;
}

.video-list .v-item .content .tags p:hover {
    background-color: rgb(134, 134, 134);
}

.video-list .v-item .content .tags > p + p {
    margin-left: 6px;
}

@media screen and (max-width: 768px) {

    .video-list {
        display: block;
    }

    .video-list .base-v-item {
        width: 100%;
        margin-bottom: 30px;
    }

    .video-list .v-item {
        max-height: 250px;
    }

    .video-list .v-item .content .desc {
        height: 100% !important;
    }
}