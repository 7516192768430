.PageContainer {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    transform-origin: top;

    .content-overflow {
        position: relative;
        height: 100%;
        overflow: hidden;
    }

    &.toggled {
        position: fixed;
        max-height: 100vh;
    }
}

.sub {
    position: relative;
    width: 100%;
    height: 100%;
}

.btnTopCenter {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%) !important;
    width: 65px;
    height: 57px;
    font-size: 2rem;
    background-color: transparent;
    transition-duration: .5s;
    border: none;
    outline: 0;
    cursor: pointer;
    z-index: 20;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-background-clip: text; /* This will bring bg shape according to text*/
        -webkit-text-fill-color: transparent;
        transition-duration: .3s;
        transition-delay: .35s;
    }

    .bgPullMenu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        transition-duration: .25s;
        transition-delay: .35s;
    }

    &:hover {
        svg {
            filter: invert(1);
            transition-delay: 0s;
        }

        .bgPullMenu {
            height: 100%;
            transition-delay: 0s;
        }
    }

    &:active, &.active {
        svg {
            filter: invert(1);
            transition-delay: 0s;
        }

        .bgPullMenu {
            height: 100%;
            opacity: 0.75;
            transition-delay: 0s;
        }
    }
}

.dimPullMenu {
    position: fixed;
    background-color: rgba(0, 0, 0, .5);
    width: 100vw;
    height: 100%;
    z-index: 15;
}

.scroll-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    will-change: transform;
}