.textBox {
    padding-right: 30px;
    position: relative;
    min-width: 320px;
    height: 100%;
}

.textBox svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: rgb(211, 211, 211);
    cursor: text;
    transition-duration: .35s;
}

.textBox input {
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    color: rgb(211, 211, 211);
    font-size: 18px;
    background-color: rgba(255, 255, 255, .05);
    border: unset;
    border-radius: 2px;
    outline: 0;
    transition-duration: .25s;
}

.textBox:hover input {
    background-color: rgba(255, 255, 255, .25);
}

.textBox input::placeholder {
    color: rgba(255, 255, 255, .5);
    transition-duration: .25s;
}

.textBox > input:hover {
    box-shadow: 0 0 5px 1px rgb(26, 26, 26);
}

.textBox > input:focus {
    box-shadow: 0 0 5px 1px rgb(26, 26, 26);
}

.textBox input:focus::placeholder {
    color: rgba(0, 0, 0, .5);
}

.textBox input:focus {
    color: rgb(51, 51, 51);
    background-color: rgb(219, 219, 219);
    transition-duration: .25s;
}

.textBox > input:focus + svg {
    color: rgb(51, 51, 51);
}