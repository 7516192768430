
.userOptionModal {
    position: fixed;
    top: 0;
    right: 0;
    transition-duration: .3s;
    z-index: 2000;

    .modalBg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #0c0c0c;
        transition-duration: .3s;
    }

    .modalContent {
        position: absolute;
        top: 60px;
        right: 0;
        // transform: translate(-50%, -50%);
        max-width: 100vw;
        width: 300px;
        // height: 300px;
        border-radius: 10px;
        transition-duration: .3s;
        box-shadow: 0 0 10px rgba(0, 0, 0, .5);

        .sub {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            background-color: #0c0c0c;
            overflow: hidden;
        }

        .closeBtn {
            position: absolute;
            top: 15px;
            right: 10px;
            width: 30px;
            height: 30px;
            cursor: pointer;
            transition-duration: .3s;

            background-color: transparent;
            border: none;
            color: white;
            font-size: 25px;
            z-index: 1000;

            &:hover {
                background-color: rgba(255, 255, 255, .15);
            }
        }

        .circleImage {
            position: relative;
            margin: 0 auto;
            width: 150px;
            border-radius: 50%;
            overflow: hidden;
            background-color: rgba(255, 255, 255, .15);
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0;
                transition-duration: .5s;
            }

            &:hover img {
                opacity: .8 !important;
            }
        }

        .menuList {
            width: 100%;
            height: 100%;
            padding-bottom: 10px;

            .menuItem {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                padding: 0 20px;
                cursor: pointer;
                
                &:hover {
                    background-color: rgba(255, 255, 255, .15);
                }

                &:active {
                    transform: scale(.95);
                }
                
                .icon {
                    width: 30px;
                    margin-right: 10px;
                    background-size: cover;
                    background-position: center;
                }

                .text {
                    font-size: 15px;
                    font-weight: 500;
                    color: white;
                }
            }
        }
    }
}
