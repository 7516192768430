.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* backdrop-filter: blur(5px); */
    background-color: rgba(0, 0, 0, .5);
    z-index: 100;
}

.side-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    background-color: #0c0c0c;
    transition-duration: .25s;
    white-space: nowrap;
    overflow: hidden;
    z-index: 800;
}

.side-menu:hover, .side-menu.active {
    width: 250px;
    box-shadow: 0 0 10px 1px #222;
}

.side-menu .btn-cls {
    display: none;
    position: absolute;
    padding: 5px 8px;
    top: 7px;
    right: 8px;
    z-index: 100;
}

.side-menu .sideOptionCover {
    position: relative;
    width: 100%;
    height: 52px;
    line-height: 52px;
    transition-duration: .25s;
}

.side-menu:hover .sideOptionCover {
    background-color: rgba(0, 0, 0, .3);
}

.side-menu .sideOptionCover .text {
    padding-left: 47px;
    white-space: nowrap;
    opacity: 1;
    transition-duration: .25s;
}

.side-menu .sideOptionCover svg {
    position: absolute;
    top: 50%;
    left: 17px;
    transform: translateY(-50%);
    width: 30px;
    font-size: 20px;
}

.creditSection {
    display: none;
    text-align: center;
    opacity: .75;
    font-size: 12px;
}

.side-menu:hover .creditSection,
.side-menu.active .creditSection {
    display: block;
}

.side-menu:hover .sideOptionCover .text {
    opacity: 1;
    transition-duration: .25s;
}

.side-menu .menu-area {
    height: calc(100% - 54px);
}

.side-menu .menu-area a {
    display: block;
    padding: 10px 10px 10px 10px;
    color: var(--font-color);
    text-decoration: unset;
    border: 1px transparent solid;
    transition-duration: .25s;
}

.side-menu:hover .menu-area a,
.side-menu.active .menu-area a {
    padding: 10px 10px 10px 20px;
}

.side-menu .menu-area a:hover {
    background-color: rgba(255, 255, 255, .1);
}

.side-menu .menu-area a:active {
    background-color: rgba(255, 255, 255, .3);
    border: 1px rgba(255, 255, 255, .2) solid;
}

.side-menu .menu-area a.active {
    background-color: rgba(255, 255, 255, .2);
}

.side-menu .menu-area a svg {
    margin-right: 10px;
    width: 30px;
}

.side-menu .menu-area a span {
    opacity: 1;
    transition-duration: .25s;
}

.side-menu:hover .menu-area a span {
    opacity: 1;
}

@media (max-width: 768px) {
    .side-menu {
        width: 0;
    }

    .side-menu:hover {
        width: 0;
    }

    .side-menu.active {
        width: 250px !important;
    }
}