.login-page {
    position: relative;
    width: 100vw;
    height: 100vh;
    color: white;

    button {
        background: rgba(255, 255, 255, .15);
        color: white;
        border: none;
        transition-duration: .3s;

        &:hover {
            background: rgba(255, 255, 255, .25);
        }

        &:active {
            background: rgba(255, 255, 255, .35);
        }
    }

    .sub {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .bgOver {
        position: absolute;
        top: 0;
        left: 0;
        width: 65vw;
        height: 100vh;
        background-image: url(../../public/images/auth-bg.jpg);
        opacity: 0.5;
        background-size: cover;
        z-index: 1;
    }
    
    .loginSide {
        position: absolute;
        top: 0;
        right: 0;
        width: 35vw;
        height: 100vh;
        background-color: var(--bg-main);
    }
    
    .centerContain {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        background-color: var(--bg-main);
    }
}

@media screen and (max-width: 768px) {
    .login-page {
        .bgOver {
            display: none;
        }

        .loginSide {
            width: 100%;
        }

        .backButton {
            padding-left: 0;
        }

        .centerContain {
            width: 80%;
        }
    }
}