
.actorModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition-duration: .3s;
    z-index: 2000;

    .modalBg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        transition-duration: .3s;
    }

    .modalContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 70vw;
        width: 560px;
        height: fit-content;
        border-radius: 10px;
        transition-duration: .3s;

        .sub {
            display: block;
            position: relative;
            padding: 20px;
            width: 100%;
            height: 100%;
            background-color: var(--bg-main);
            overflow: hidden;
            border-radius: 10px;
        }

        .deleteBtn {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 30px;
            height: 30px;
            cursor: pointer;
            transition-duration: .3s;

            background-color: transparent;
            border: none;
            color: white;
            font-size: 15px;
            z-index: 1000;

            &:hover {
                background-color: rgba(255, 255, 255, .15);
            }
        }

        .circleImage {
            position: relative;
            margin: 0 auto;
            width: 150px;
            height: 150px;
            border-radius: 50%;
            overflow: hidden;
            background-color: rgba(255, 255, 255, .15);
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0;
                transition-duration: .5s;
            }

            &:hover img {
                opacity: .8 !important;
            }
        }

        .inputBox {
            position: relative;
            margin-top: 20px;
            width: 100%;
            height: 40px;
            border-radius: 5px;
            background-color: rgba(255, 255, 255, .15);
            overflow: hidden;
        }

        .btnBox {
            position: relative;
            width: 100%;
            // height: 40px;
            border-radius: 5px;
            background-color: transparent;
            overflow: hidden;
        }

        .sidePanel {
            width: 100%;
            display: flex;
        }

        .leftSide {
            position: relative;
            width: 360px;
        }

        .rightSide {
            position: relative;
            padding: 0;
            width: 200px;
            max-height: 160px;

            
        }
    }

    .sampleList {
        padding: 10px;
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 80px;
        max-height: 110px;
        background-color: rgba(255, 255, 255, .05);
        border: 1px solid rgba(255, 255, 255, .25);
        border-radius: 10px;
        overflow-x: hidden;
        overflow-y: auto;
        cursor: pointer;

        .sampleItem {
            padding: 10px;
            width: 100%;
            height: 40px;
            background-color: rgba(255, 255, 255, .1);
            border-radius: 5px;
            margin-bottom: 10px;
            overflow: hidden;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition-duration: .3s;

            &:hover {
                background-color: rgba(255, 255, 255, .15);
            }

            &:active {
                background-color: rgba(255, 255, 255, .1);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .actorModal {
        .modalContent {
            max-width: 90vw;
            width: 90vw;
        }
    }
}