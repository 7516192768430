#uploaderPage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 10000;

    .sub {
        padding-top: 50px;
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #505050;
    }

    .closeButton {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        transition-duration: .3s;

        background-color: transparent;
        border: none;
        color: white;
        font-size: 25px;
        z-index: 2000;
        outline: 0;

        &:hover {
            background-color: rgba(255, 255, 255, .15);
        }

        &:active {
            background-color: rgba(255, 255, 255, .3);
        }
    }

    .topPanel {
        display: flex;
        width: 100vw;
        height: 50%;
        background-color: rgba(0, 0, 0, .15);

        .sidePanel {
            position: relative;
            width: 30vw;
            min-width: 300px;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
        }

        .playerPanel {
            position: relative;
            width: 50vw;
            height: 100%;
            background-color: black;
        }
    }

    .bottomPanel {
        position: relative;
        display: flex;
        width: 100vw;
        height: 50%;
        background-color: rgba(0, 0, 0, .5);

        .sidePanel {
            position: relative;
            width: 30vw;
            min-width: 300px;
            height: 100%;
        }

        .editorPanel {
            position: relative;
            width: 50vw;
            height: 100%;
            background-color: rgba(0, 0, 0, .25);
        }
    }
}

#uploadListPanel {
    position: relative;
    width: 100%;
    height: 100%;

    .topic {
        width: 100%;
        height: 50px;
        padding-left: 20px;
        // background-color: rgba(0, 0, 0, .5);
        color: white;
        font-size: 20px;
        font-weight: bold;
        line-height: 50px;
    }

    .vid-list {
        position: relative;
        width: 100%;
        height: calc(100% - 50px);
        overflow-y: auto;
        overflow-x: hidden;

        .vid-item {
            position: relative;
            width: 100%;
            min-height: 40px;
            // background-color: rgba(0, 0, 0, .5);
            border-top: 1px solid rgba(255, 255, 255, .1);
            border-bottom: 1px solid rgba(255, 255, 255, .1);
            cursor: pointer;
            transition-duration: .3s;

            &:hover {
                background-color: rgba(255, 255, 255, 0.05);
            }

            &:active, &.active {
                background-color: rgba(255, 255, 255, 0.1);
            }

            .vid-info {
                position: relative;
                width: 100%;
                height: 100%;
                padding: 5px 10px;
                overflow: hidden;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title {
                    font-size: 18px;
                    color: white;
                    transition-duration: .3s;
                }

                .right-side-option {
                    position: relative;
                    width: 30%;
                    height: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    transition-duration: .3s;

                    .upload-progress-bar {
                        position: relative;
                        width: 100%;
                        height: 7px;
                        background-color: rgba(255, 255, 255, .25);
                        border-radius: 2px;
                        overflow: hidden;

                        .progress {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 0;
                            height: 100%;
                            background-color: white;
                            border-radius: 5px;
                            transition-duration: .3s;
                        }
                    }

                    .option {
                        position: relative;
                        width: 15px;
                        height: 30px;
                        margin-left: 20px;
                        background-color: transparent;
                        border: none;
                        color: rgba(255, 255, 255, .5);
                        font-size: 15px;
                        transition-duration: .3s;
                        cursor: pointer;
                        outline: 0;

                        &:hover {
                            color: white;
                        }

                        &:active {
                            color: rgba(255, 255, 255, .75);
                        }
                    }
                }
            }

            .vid-thumbnail {
                position: relative;
                width: 100px;
                height: 100%;
                background-color: black;
                border-radius: 5px;
            }
        }
    }
}

#PlayerPanel {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: black;

    .videoPlayer {
        position: relative;
        width: 100%;
        height: 100%;
    }
}

#tagTimeline {
    position: relative;
    width: 100%;
    height: 100%;

    .topic {
        width: 100%;
        height: 50px;
        padding-left: 20px;
        // background-color: rgba(0, 0, 0, .5);
        color: white;
        font-size: 20px;
        font-weight: bold;
        line-height: 50px;
    }

    .tag-list {
        position: relative;
        padding: 10px;
        width: 100%;
        height: calc(100% - 50px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 50px;

        .tag-item {
            padding: 5px 10px;
            width: 100%;
            height: 40px;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 7px;
            margin-bottom: 10px;
            overflow: hidden;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition-duration: .3s;

            &:hover {
                background-color: rgba(255, 255, 255, 0.15);
            }

            &:active {
                background-color: rgba(255, 255, 255, 0.1);
            }

            &.highlight {
                background-color: rgba(255, 255, 255, 0.3);
            }

            .time {
                width: fit-content;
                font-size: 15px;
                color: white;
                white-space: nowrap;
                transition-duration: .3s;
            }

            .circleImg {
                position: relative;
                margin-left: 5px;
                min-width: 30px;
                min-height: 30px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 5px;
                background-color: rgba(255, 255, 255, .1);

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: translate(-50%, -50%);
                }
            }

            .tag-info {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .tag-name {
                    padding-left: 5px;
                    font-size: 15px;
                    color: white;
                    transition-duration: .3s;
                }

                .option {
                    position: relative;
                    width: 15px;
                    margin-left: 20px;
                    background-color: transparent;
                    border: none;
                    color: rgba(255, 255, 255, .5);
                    font-size: 15px;
                    transition-duration: .3s;
                    cursor: pointer;
                    outline: 0;

                    &:hover {
                        color: white;
                    }

                    &:active {
                        color: rgba(255, 255, 255, .75);
                    }
                }
            }
        }
    }

    .tag-bottom-panel {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        display: flex;
        background-color: #222222;

        .addTagBtn {
            font-size: 25px;
            transition-duration: .3s;

            &:hover {
                transform: scale(1.1);
            }

            &:active {
                transform: scale(1);
                transition-duration: .1s;
            }
        }
    }

}

#IngredientPanel {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    // background-color: #1e2731;

    .topic {
        width: 100%;
        height: 50px;
        padding-left: 20px;
        // background-color: rgba(0, 0, 0, .5);
        color: white;
        font-size: 20px;
        font-weight: bold;
        line-height: 50px;
        background-color: #3049b7;
    }

    .hr {
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, .3);
        margin-bottom: 5px;
    }

    .tag-list {
        position: relative;
        padding: 10px;
        width: 100%;
        display: block;
        // grid-template-columns: repeat(3, 1fr);

        .tag-item {
            padding: 5px 10px;
            width: 100px;
            height: 100px;
            text-align: center;
            // background-color: rgba(255, 255, 255, 0.1);
            margin-bottom: 10px;
            overflow: hidden;
            cursor: pointer;
            display: inline-block;
            justify-content: space-between;
            align-items: center;
            transition-duration: .3s;

            &:hover {
                background-color: rgba(255, 255, 255, 0.05);
            }

            &:active {
                background-color: rgba(255, 255, 255, 0.1);
            }

            .time {
                width: fit-content;
                font-size: 15px;
                color: white;
                white-space: nowrap;
                transition-duration: .3s;
            }

            .circleImg {
                position: relative;
                margin-left: 5px;
                min-width: 50px;
                min-height: 70px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 5px;
                background-color: #2258ffa6;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: translate(-50%, -50%);
                }
            }

            .tag-title {
                margin: auto;
                text-align: center;
                padding-top: 5px;
                font-size: 15px;
                color: white;
                transition-duration: .3s;
            }
        }
    }

    .tag-bottom-panel {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        display: flex;
        background-color: #222222;

        .addTagBtn {
            font-size: 25px;
            transition-duration: .3s;

            &:hover {
                transform: scale(1.1);
            }

            &:active {
                transform: scale(1);
                transition-duration: .1s;
            }
        }
    }

}

#submitPanel {
    position: relative;
    width: 100%;
    height: 100%;

    .topic {
        width: 100%;
        height: 50px;
        padding: 0 20px;
        // background-color: rgba(0, 0, 0, .5);
        color: white;
        font-size: 18px;
        font-weight: bold;
        line-height: 50px;
    }

    .tag-top-panel {
        padding: 10px;
        width: 100%;
        height: 60px;
        display: flex;

        .addTagBtn {
            font-size: 25px;
            transition-duration: .3s;

            &:hover {
                transform: scale(1.1);
            }

            &:active {
                transform: scale(1);
                transition-duration: .1s;
            }
        }
    }

    .info-data {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;

        .name {
            padding: 0 10px;
            color: white;
            font-size: 15px;
            transition-duration: .3s;
        }

        .data {
            padding: 0 10px;
            color: white;
            font-size: 15px;
            transition-duration: .3s;
        }
    }

    .tag-bottom-panel {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        display: flex;
        padding: 10px;
        background-color: #222222;

    }
}

#DescriptionPanel {
    position: relative;
    width: 100%;
    height: 100%;

    .topic {
        width: 100%;
        height: 50px;
        padding: 0 20px;
        // background-color: rgba(0, 0, 0, .5);
        color: white;
        font-size: 18px;
        font-weight: bold;
        line-height: 50px;
    }
}

@media screen and (max-width: 1200px) {
    #uploaderPage {
        overflow-y: auto;
        background-color: var(--bg-main);

        .topPanel {
            display: block;
            height: unset;

            .sidePanel {
                width: 100%;
                height: 300px;
                background-color: #1B1B1C;
            }

            .playerPanel {
                width: 100%;
                height: 300px;
            }
        }

        .bottomPanel {
            display: block;
            height: unset;

            .sidePanel {
                width: 100%;
                height: 300px;
                background-color: #1B1B1C;
            }

            .editorPanel {
                width: 100%;
                height: 300px;
            }
        }
    }
}